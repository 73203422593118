import { useCallback, useMemo } from 'react'

import { useTranslations } from 'next-intl'

import useChainContextStore from 'store/chainContext'
import useCurrentUserStore from 'store/currentUser'

import { SystemRoles } from 'types/enums/systemRoles'

import type { ValueLabelPair } from '@repo/et-types'

interface UseCurrentUserRolesReturn {
  getOrganizationUserRole: (organizationId?: number) => ValueLabelPairWithId
  isChainAdmin: boolean
  isAdminOfCurrentChain: boolean
  isAdminOfCurrentOrg: boolean
}

type ValueLabelPairWithId = ValueLabelPair & { id?: number }

const useCurrentUserRoles = (): UseCurrentUserRolesReturn => {
  const t = useTranslations()
  const [chain, isChainAdmin, organizations, isMultiOrg] = useCurrentUserStore((state) => [
    state.chain,
    state.chain_admin,
    state.organizations,
    state.isMultiOrg
  ])

  const [selectedOrganization, selectedChain] = useChainContextStore((state) => [
    state.selectedOrganization,
    state.selectedChain
  ])

  const getOrganizationUserRole = useCallback(
    (organizationId = selectedOrganization?.id): ValueLabelPairWithId => {
      const organization = organizations.find((org) => org.id === organizationId)

      return {
        id: organization?.membership?.role.id,
        value: organization?.membership?.role.name || SystemRoles.REGULAR,
        label: t(`roles.${organization?.membership?.role.name || SystemRoles.REGULAR}`)
      }
    },
    [organizations, t, selectedOrganization?.id]
  )

  const canViewChainContext = useMemo(
    () =>
      Boolean(
        chain &&
          isChainAdmin &&
          chain.organizations_count &&
          chain.organizations_count > 1 &&
          organizations.length > 0
      ),
    [chain, isChainAdmin, organizations.length]
  )

  const isAdminOfCurrentOrg = useMemo<boolean>(
    () =>
      Boolean(
        selectedOrganization &&
          getOrganizationUserRole(selectedOrganization.id).value === SystemRoles.ADMIN
      ),
    [selectedOrganization, getOrganizationUserRole]
  )

  const isAdminOfCurrentChain = useMemo<boolean>(() => {
    if (isMultiOrg || canViewChainContext) {
      return Boolean(selectedChain && chain?.id === selectedChain?.id && isChainAdmin)
    }

    return isAdminOfCurrentOrg
  }, [selectedChain, chain?.id, isAdminOfCurrentOrg, isMultiOrg, isChainAdmin, canViewChainContext])

  return {
    getOrganizationUserRole,
    isChainAdmin,
    isAdminOfCurrentChain,
    isAdminOfCurrentOrg
  }
}

export default useCurrentUserRoles
